.row{
    margin-bottom:  0px ;
}

.clear-format{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.vote-tally{
    font-size: x-large;
    padding-left: 5px;
    padding-right: 5px;
    position:relative;
   
}
.vote-container{
 padding: 5px;
 text-align: right;
}

.row{
    margin-top: 30px;
}